<template>
  <div>
    <h2>処理中…</h2>
    <h3>お待ちください。</h3>
  </div>
</template>

<script>
import Constants from '@constants'
import PurchaseService from '@services/PurchaseService'

export default {
  data() {
    return {
      params: null
    }
  },
  created() {
    // URLパラメータを取得
    this.params = this.$route.query

    const access_id = this.params.AccessID
    const order_id = this.params.OrderID
    const client_uuid = this.$cookies.get(Constants.CLIENT_UUID)

    // 3Dセキュア認証結果を送信
    if (access_id && order_id && client_uuid) {
      PurchaseService.resolve3DSecure(access_id, order_id, client_uuid).then(() => {
        this.$router.push({name: 'purchase.fix'})
      }).catch(() => {
        this.$store.dispatch('setGlobalError', { error: `決済エラーが発生しました。<br>カード会社にお問い合わせください。` })
        this.$router.push({name: 'cart.page'})
      })
    }
  }
}
</script>

<style scoped>
h2 {
  margin-bottom: 20px;
}

h3 {
  margin-top: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}
</style>
